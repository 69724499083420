.container {
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading2Xl {
  font-size: xx-large;
  text-align: center;
  color: aliceblue;
  margin: 0;
  line-height: 1.25;
}
.headingXl {
  font-size: x-large;
  text-align: center;
  font-weight: 500;
  color: aliceblue;
  margin: 0;
}
.headingLg {
  font-size: large;
  font-weight: 500;
  text-align: center;
  color: rgba(240, 248, 255, 0.92);
  margin: 0;
}
.headingLgn {
  font-size: large;
  text-align: center;
  color: aliceblue;
  margin: 0;
}
.headingMd {
  font-size: medium;
  font-weight: 400;
  text-align: center;
  color: aliceblue;
  margin: 0;
}
.heading {
  font-size: small;
  text-align: center;
  font-weight: 400;
  color: aliceblue;
  margin: 0;
}
.headingSm {
  font-size: smaller;
  text-align: center;
  color: aliceblue;
  margin: 0;
}
.headingBlock {
  padding-top: 32px;
  padding-bottom: 32px;
}
.hide {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 50%;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(22%, #0052fe),
    to(rgba(24, 23, 31, 0))
  );
  background-image: linear-gradient(270deg, #0052fe 22%, rgba(24, 23, 31, 0));
}
.navLink {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: medium;
}
.myBtnImage {
  display: block;
  color: black;
  background-color: white;
  text-align: center;
  padding: 2px;
  text-decoration: none;
  font-size: medium;
  border-style: none;
  border-radius: 20px;
}
.myBtn {
  display: block;
  color: black;
  background-color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: medium;
  border-style: none;
  border-radius: 20px;
}
.myBtnSm {
  display: block;
  color: black;
  background-color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: small;
  border-style: none;
  border-radius: 20px;
}
